import React from 'react';

const SvgShare = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h18v18H0z" />
      <path
        d="M9 14a.792.792 0 01-.805-.778V6.66c0-.43.36-.779.805-.779.445 0 .805.349.805.779v6.562c0 .43-.36.778-.805.778zM9 2.903L4.663 7.186a.688.688 0 01-.963 0 .667.667 0 010-.952L9 1l5.3 5.234a.667.667 0 010 .952.688.688 0 01-.963 0L9 2.903z"
        fill="#38383F"
      />
      <path
        d="M16.457 16.588H1.543v-5.882c0-.39-.346-.706-.772-.706-.426 0-.771.316-.771.706V18h18v-7.294c0-.39-.345-.706-.771-.706-.426 0-.772.316-.772.706v5.882z"
        fill="#38383F"
      />
    </g>
  </svg>
);

export default SvgShare;
