import React from 'react';
import cx from 'classnames';
import copy from 'copy-to-clipboard';
import { useTransition, animated } from 'react-spring';
import CheckIcon from '../../svg/CheckIcon';
import ShareIcon from '../../svg/ShareIcon';
import styles from './shareButton.module.scss';

const AnimatedCheckIcon = animated(CheckIcon);
const AnimatedShareIcon = animated(ShareIcon);

export default function ShareButton({ link = '' }) {
  const [copied, setCopied] = React.useState(false);
  const transitions = useTransition(copied, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  if (typeof window !== 'undefined' && !link) {
    link = window.location.href;
  }

  return (
    <div className={cx(styles.container, { [styles.copied]: copied })}>
      <button
        className={cx(styles.button, { [styles.copied]: copied })}
        onClick={() => {
          copy(link);
          setCopied(true);
        }}
        onMouseLeave={() => {
          if (copied) {
            setCopied(false);
          }
        }}
      >
        <div className={styles.content}>
          <p>{copied ? 'Link Copied' : 'Share with friends'}</p>
          {transitions.map(({ item, key, props }) =>
            item ? (
              <AnimatedCheckIcon key={key} style={props} />
            ) : (
              <AnimatedShareIcon key={key} style={props} />
            )
          )}
        </div>
      </button>
    </div>
  );
}
