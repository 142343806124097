import React from 'react';

const SvgWebsite = props => (
  <svg width="1em" height="1em" viewBox="0 0 33 33" {...props}>
    <g transform="translate(-4.313 -4.313)" fill="none" fillRule="evenodd">
      <path d="M0 0h41.188v41.188H0z" />
      <circle
        stroke="#38383F"
        strokeWidth={1.144}
        cx={20.594}
        cy={20.594}
        r={15.56}
      />
      <ellipse
        stroke="#38383F"
        strokeWidth={1.144}
        cx={20.594}
        cy={20.594}
        rx={7.78}
        ry={15.56}
      />
      <path fill="#38383F" d="M20.108 5.034h1v31.12h-1z" />
      <path fill="#38383F" d="M36.154 20.594v1H5.034v-1z" />
    </g>
  </svg>
);

export default SvgWebsite;
