import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';
import Tooltip from '../../../components/Tooltip';
import AreaIcon from '../../../svg/AreaIcon';
import WebsiteIcon from '../../../svg/WebsiteIcon';
import { getHumanizedDurationString } from '../../../utils/dateTimeFormatting';
import styles from './stop.module.scss';

Stop.propTypes = {
  stop: PropTypes.object.isRequired,
};

const stopToColorMap = [
  { className: 'first' },
  { className: 'second' },
  { className: 'third' },
  { className: 'fourth' },
];

const serializers = {
  marks: {
    internalLink: ({ mark, children }) => {
      const { slug = {} } = mark
      const href = `/${slug.current}`
      return <a href={href}>{children}</a>
    },
    link: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark
      return blank ?
        <a href={href} target="_blank" rel="noopener">{children}</a>
        : <a href={href}>{children}</a>
    }
  }
}

export default function Stop({ stop, index }) {
  const isEvent = Boolean(stop.event);
  let image;
  let title;
  let priceRange;
  let description;
  let googleMapsPlaceId;
  let address;
  let website;

  if (isEvent) {
    const { event } = stop;
    image = event.image || event.place.image;
    title = event.title || event.place.title;
    priceRange = event.priceRange || event.place.priceRange;
    description = event._rawDescription || event.place._rawDescription;
    googleMapsPlaceId = event.place?.googleMapsPlaceId;
    address = event.place?.address;
    website = event.website || event.place?.website;
  } else {
    const { place } = stop;
    image = place.image;
    title = place.title;
    priceRange = place.priceRange;
    description = place._rawDescription;
    googleMapsPlaceId = place.googleMapsPlaceId;
    address = place.address;
    website = place.website;
  }

  return (
    <li className={styles.wrapper}>
      <article className={styles.container}>
        <div className={styles.directions}>
          <div
            className={`${styles.stopNumber} ${
              styles[stopToColorMap[index % stopToColorMap.length].className]
            }`}
          >
            {index + 1}
          </div>
          <h2>{stop.navigationTitle}</h2>
        </div>
        <div className={styles.content}>
          {(image?.asset && (
            <Img
              className={styles.image}
              alt={title}
              fixed={image?.asset.fixed}
            />
          )) ??
            null}
          <div className={styles.text}>
            <h4 className={styles.title}>{title}</h4>
            <p className={styles.subtitle}>
              {Number.parseInt(stop.duration) < 180
                ? getHumanizedDurationString(stop.duration, 2)
                : '3 hours +'}{' '}
              <span className={styles.divider}>|</span>
              {priceRange?.value}
            </p>
            <div className={styles.description}>
              <BlockContent blocks={description} serializers={serializers} />
            </div>
          </div>
          <div className={styles.links}>
            <div
              className={styles.linkIcon}
              data-for="maps"
              data-tip="Google Maps"
            >
              <a
                href={`https://www.google.com/maps/search/?api=1&query_place_id=${googleMapsPlaceId}&query=${address?.replace(
                  / /g,
                  '+'
                )}`}
                target="_blank"
                rel="nofollow noopener"
              >
                <AreaIcon className={styles.linkSvg} />
              </a>
            </div>
            <Tooltip effect="solid" place="top" id="maps" label="Notifications">
              Google Maps
            </Tooltip>
            <div
              className={styles.linkIcon}
              data-for="website"
              data-tip="Website"
            >
              <a href={website} target="_blank" rel="nofollow noopener">
                <WebsiteIcon className={styles.linkSvg} />
              </a>
            </div>
            <Tooltip
              effect="solid"
              place="top"
              id="website"
              label="Notifications"
            >
              Website
            </Tooltip>
          </div>
        </div>
      </article>
    </li>
  );
}
