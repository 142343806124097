import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import styles from './GoogleMaps.module.scss';

const AUSTIN_CENTER = [30.2672, 97.7431];

const getMapOptions = maps => {
  return {
    disableDefaultUI: true,
    mapTypeControl: false,
    streetViewControl: false,
    styles: [
      {
        featureType: 'poi',
        elementType: '',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ visibility: 'simplified' }],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }],
      },
    ],
  };
};

// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach(place => {
    bounds.extend(
      new maps.LatLng(place.geometry.location.lat, place.geometry.location.lng)
    );
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });
  });
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, places) => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);
};

const GoogleMap = ({ places }) => {
  const mapsPlaces = places.map(place => ({
    geometry: {
      location: { lat: place?.coordinates?.lat, lng: place?.coordinates?.lng },
    },
  }));

  return places.length > 0 &&
    mapsPlaces.every(
      mapsPlace =>
        mapsPlace?.geometry?.location?.lat && mapsPlace?.geometry?.location?.lng
    ) ? (
    <div className={styles.container}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
        defaultCenter={AUSTIN_CENTER}
        defaultZoom={10}
        options={getMapOptions()}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) =>
          apiIsLoaded(map, maps, mapsPlaces)
        }
      >
        {places.map((place, index) => (
          <Marker
            key={place.title}
            text={place.title}
            lat={place?.coordinates.lat}
            lng={place?.coordinates.lng}
            color="red"
            index={index}
          />
        ))}
      </GoogleMapReact>
    </div>
  ) : null;
};

export default GoogleMap;
