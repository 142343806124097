import React from 'react';
import copy from 'copy-to-clipboard';
import ToastNotification from '../ToastNotification';
import CheckIcon from '../../svg/CheckIcon';
import ShareIcon from '../../svg/ShareIcon';
import styles from './shareButtonSmall.module.scss';
import { toast } from 'react-toastify';

export default function ShareButtonSmall({ link = '' }) {
  if (typeof window !== 'undefined' && !link) {
    link = window.location.href;
  }

  const notify = () =>
    toast(<ToastNotification text="Link Copied" icon={<CheckIcon />} />);

  return (
    <button
      className={styles.button}
      onClick={() => {
        copy(link);
        notify();
      }}
    >
      <ShareIcon />
    </button>
  );
}
