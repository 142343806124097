import React, { useMemo } from 'react';
import cx from 'classnames';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';
import BackArrowButton from '../../components/BackArrowButton';
import ExperienceInfoItem from '../../components/ExperienceInfoItem';
import GoogleMaps from '../../components/GoogleMaps';
import Layout from '../../components/layout';
import SEO from '../../components/SEO';
import ShareButton from '../../components/ShareButton';
import ShareButtonSmall from '../../components/ShareButtonSmall';
import ToastContainer from '../../components/ToastContainer';
import XupFooter from '../../components/XupFooter';
import Stop from './Stop';
import useExperienceDuration from '../../hooks/useExperienceDuration';
import useScrollPosition from '../../hooks/useScrollPosition';
import useWindowWidth from '../../hooks/useWindowWidth';
import { formatDateStringRelativeToToday } from '../../utils/dateTimeFormatting';
import styles from './experience.module.scss';

function Experience({ activeDate, dateRangeLowerLimit, experience }) {
  let width = useWindowWidth();
  const [fixedDate, setfixedDate] = React.useState(false);
  let position = useScrollPosition();
  let dateRef = React.useRef();
  const isTableScreenWidth = width < 1024;

  let dateTop = dateRef?.current?.getBoundingClientRect()?.top ?? 0;
  if (dateTop < 60) {
    if (!fixedDate) {
      setfixedDate(true);
    }
  } else {
    if (fixedDate) {
      setfixedDate(false);
    }
  }

  const durationMessage = useExperienceDuration(experience.enhancedStops);

  const serializers = {
    marks: {
      internalLink: ({ mark, children }) => {
        const { slug = {} } = mark
        const href = `/${slug.current}`
        return <a href={href}>{children}</a>
      },
      link: ({ mark, children }) => {
        // Read https://css-tricks.com/use-target_blank/
        const { blank, href } = mark
        return blank ?
          <a href={href} target="_blank" rel="noopener">{children}</a>
          : <a href={href} target="_blank" rel="noopener">{children}</a>
      }
    }
  }

  return (
    <>
      <aside className={styles.details}>
        <div>
          <BackArrowButton
            to="/"
            style={isTableScreenWidth ? { position: 'fixed' } : {}}
          />
          <ShareButtonSmall />
          <Img
            className={styles.heroImage}
            alt={experience.title}
            fluid={experience.image.asset.fluid}
            style={{
              opacity:
                dateTop && isTableScreenWidth
                  ? `${(Number(dateTop) - 60.0) / 100.0}`
                  : 1,
            }}
          />
          {fixedDate && isTableScreenWidth && (
            <div
              style={{
                height: '127px',
                background: 'white',
                position: 'fixed',
                top: '0',
                width: '100vw',
                zIndex: '1',
              }}
            ></div>
          )}
          <div className={styles.detailsInfo} ref={dateRef}>
            <h2
              className={cx(styles.date, {
                [styles.fixed]: fixedDate && isTableScreenWidth,
              })}
            >
              {formatDateStringRelativeToToday(
                dateRangeLowerLimit,
                activeDate?.slice(0, 10)
              )}
            </h2>
            <p
              className={styles.category}
              style={{
                marginTop: fixedDate && isTableScreenWidth ? '60px' : '0',
              }}
            >
              {experience.category.name}
            </p>
            <h3 className={styles.title}>{experience.title}</h3>
            <div className={styles.experienceInfo}>
              <div className={styles.experienceInfoRow}>
                <ExperienceInfoItem
                  size="large"
                  type="duration"
                  value={durationMessage}
                />
                <ExperienceInfoItem
                  size="large"
                  type="itinerary"
                  value={`${experience.enhancedStops.length} stops`}
                />
              </div>

              <div className={styles.experienceInfoRow}>
                <ExperienceInfoItem
                  size="large"
                  type="area"
                  value={experience.partOfTown.name}
                />
                <ExperienceInfoItem
                  size="large"
                  type="cost"
                  value={experience.costCategory.category}
                />
              </div>
            </div>
            <section className={styles.toBringContainer}>
              <div className={styles.toBringTitle}>What to bring</div>
              <div className={styles.toBringText}>
                <BlockContent blocks={experience._rawWhatToBring}
                  serializers={serializers} />
              </div>
            </section>
            <section className={styles.shareContainer}>
              <ShareButton />
            </section>
          </div>
        </div>
        {width > 1024 && <XupFooter />}
      </aside>
      <main className={styles.stopContainer}>
        <GoogleMaps
          places={experience.enhancedStops.map(stop =>
            stop.event ? stop.event.place : stop.place
          )}
        />
        <ul className={styles.stopList}>
          {experience.enhancedStops.map((stop, index) => (
            <Stop key={index} stop={stop} index={index} />
          ))}
        </ul>
        {width <= 1024 && <XupFooter />}
      </main>
      <ToastContainer />
    </>
  );
}

export default Experience;
