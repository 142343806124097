import React from 'react';
import styles from './toastNotification.module.scss';

export default function ToastNotification({ text, icon }) {
  return (
    <div className={styles.container}>
      <p className={styles.container}>{text}</p>
      {icon}
    </div>
  );
}
