import React from 'react';
import cx from 'classnames';
import styles from './Marker.module.scss';

const Marker = ({ name, index }) => {
  return (
    <div className={cx(styles.marker, styles[`stop${index}`])} title={name}>
      {index + 1}
    </div>
  );
};

export default Marker;
