import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import Experience from '../features/Experience';

function ExperienceTemplate({ data, location, pageContext }) {
  const { sanityExperienceInstance: experienceInstance } = data;
  return (
    <Layout location={location}>
      <SEO
        title={`${experienceInstance.experience.title}`}
        imageSrc={experienceInstance.experience.image.asset.fixed.src}
        imageHeight={experienceInstance.experience.image.asset.fixed.height}
        imageWidth={experienceInstance.experience.image.asset.fixed.width}
      />
      <Experience
        activeDate={experienceInstance.activeDate.local}
        dateRangeLowerLimit={pageContext.dateRangeLowerLimit}
        experience={experienceInstance.experience}
      />
    </Layout>
  );
}

export default ExperienceTemplate;

export const pageQuery = graphql`
  query ExperienceInstanceById($id: String!) {
    sanityExperienceInstance(id: { eq: $id }) {
      activeDate {
        local
      }
      experience {
        title
        _updatedAt(formatString: "MMMM Do, YYYY")
        category {
          name
        }
        availableDays
        costCategory {
          category
        }
        _rawDescription(resolveReferences: { maxDepth: 10 })
        image {
          asset {
            fluid(maxHeight: 544, maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
          asset {
            fixed(width: 960) {
              ...GatsbySanityImageFixed
            }
          }
        }
        partOfTown {
          name
        }
        enhancedStops {
          duration
          navigationTitle
          place {
            ...placeFields
          }
          event {
            ...eventFields
          }
        }
        tags {
          name
        }
        timeOfDay {
          name
        }
        _rawWhatToBring(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
