import React from 'react';

const SvgCheck = props => (
  <svg width="1em" height="1em" viewBox="0 0 14 13" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-3-3h20.412v20.412H-3z" />
      <path
        stroke="#FCFCFC"
        strokeWidth={1.616}
        strokeLinecap="round"
        d="M1.536 8.34l3.402 3.402 7.938-10.206"
      />
    </g>
  </svg>
);

export default SvgCheck;
